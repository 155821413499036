/* eslint-disable jsx-a11y/aria-role */
import React, { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Form, FormGroup, Container } from 'reactstrap';
import { useLocation } from '@reach/router';
import { parse } from 'query-string';
import { Link } from 'gatsby';
import { verifyToken, resetMessage } from '../redux/actions/customer/customer';
import Stock001 from '../images/assets/stock001.png';
import Stock002 from '../images/assets/stock002.png';
import Staff001 from '../images/assets/staff001.png';
import Truck001 from '../images/assets/truck001.png';
import Truck002 from '../images/assets/truck002.png';
import ImgLogo from '../images/logoLNext-edit-old-2nd.png';
import '../components/publicLayout.css';

const ChangeEmail = () => {
  const location = useLocation();
  const searchParams = parse(location.search);

  const dispatch = useDispatch();
  const errorMessage = useSelector(
    (state: any) => state.customer.errorMessage,
    shallowEqual
  );
  const isSuccess = useSelector(
    (state: any) => state.customer.isSuccess,
    shallowEqual
  );
  useEffect(() => {
    if (searchParams.token !== undefined) {
      dispatch(verifyToken({ update_email_token: searchParams.token }));
    }
  }, [dispatch]);

  useEffect(
    () => () => {
      dispatch(resetMessage());
    },
    [dispatch]
  );
  return (
    <>
      <div className="wrapper">
        <Link to="/customer">
          TOPに戻る
          <span style={{ color: '#e00010', fontWeight: 600 }}>{' ＞'}</span>
        </Link>
        <div className="main">
          <Container fluid>
            {/* <div style={{ textAlign: 'center' }}> */}
            <div
              style={{ fontSize: '40px', textAlign: 'center' }}
              className="l-next-logo"
            >
              <Link to="/">
                <img src={ImgLogo} alt="logo" className="logo-header-e" />
              </Link>
            </div>
            <Form>
              <FormGroup
                style={{
                  textAlign: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                {isSuccess ? (
                  <div style={{ width: '30%' }}>
                    <blockquote
                      style={{
                        display: 'grid',
                        WebkitTextStrokeWidth: '0px',
                        background: 'rgb(232, 249, 244)',
                        border: '1px solid rgb(142, 227, 200)',
                        boxSizing: 'border-box',
                        clear: 'right',
                        color: '#181818',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontVariantCaps: 'normal',
                        fontVariantLigatures: 'normal',
                        fontWeight: 'normal',
                        letterSpacing: 'normal',
                        lineHeight: '1.6em',
                        margin: '1.5em 0px',
                        padding: '1.6em',
                        textAlign: 'center',
                        textDecorationColor: 'initial',
                        textDecorationStyle: 'initial',
                        textIndent: '0px',
                        textTransform: 'none',
                        whiteSpace: 'normal',
                        wordSpacing: '0px',
                      }}
                    >
                      <strong style={{ boxSizing: 'border-box' }}>
                        メールアドレスの変更が完了致しました。
                      </strong>
                    </blockquote>
                  </div>
                ) : (
                  ''
                )}
                {errorMessage !== '' ? (
                  <blockquote
                    style={{
                      display: 'grid',
                      WebkitTextStrokeWidth: '0px',
                      background: 'rgb(255, 233, 233)',
                      border: '1px solid rgb(251, 196, 196)',
                      boxSizing: 'border-box',
                      clear: 'right',
                      color: '#181818',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontVariantCaps: 'normal',
                      fontVariantLigatures: 'normal',
                      fontWeight: 'normal',
                      letterSpacing: 'normal',
                      lineHeight: '1.6em',
                      margin: '1.5em 0px',
                      padding: '1.6em',
                      textAlign: 'center',
                      textDecorationColor: 'initial',
                      textDecorationStyle: 'initial',
                      textIndent: '0px',
                      textTransform: 'none',
                      whiteSpace: 'normal',
                      wordSpacing: '0px',
                    }}
                  >
                    <strong style={{ boxSizing: 'border-box' }}>
                      {errorMessage}
                    </strong>
                  </blockquote>
                ) : (
                  <div />
                )}
              </FormGroup>
            </Form>
          </Container>
        </div>

        <div className="bg">
          <img src={Stock001} className="stock001" alt="Stock001" />
          <img src={Stock002} className="stock002" alt="Stock002" />
          <img src={Staff001} className="staff001" alt="Staff001" />
          <img src={Truck001} className="truck001" alt="Truck001" />
          <img src={Truck002} className="truck002" alt="Truck002" />
        </div>
      </div>
    </>
  );
};

export default ChangeEmail;
